<script lang="ts" setup>
import IconMastercard from '@/shared/assets/icons/IconMastercard.svg'
import IconVisa from '@/shared/assets/icons/IconVisa.svg'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'

import { geoEntity } from '@/entities/geo'

import { authFeature } from '@/features/auth'

import { FooterLinks } from '@/widgets/footer'
import { CookiesAccessModal } from '@/widgets/modal/cookies-access/modal'
import { CookiesAccessTopbar } from '@/widgets/modal/cookies-access/toolbar'

const $modal = useModal()
const authAutoLogin = authFeature.model.useAuthAutoLogin()

const state = reactive({
  countryReady: false,
  isUSA: false,
})

onMounted(async () => {
  await authAutoLogin(true)
  if (cookiesAccess.needShow) $modal?.open('cookies-access-tollbar')
  //
  state.isUSA = await geoEntity.model.isUSA()
  state.countryReady = true
})
</script>

<template>
  <footer>
    <div class="mx-auto max-w-[1100px] pt-24">
      <footer-links class="text-center" />
      <div v-if="state.countryReady">
        <p
          v-if="state.isUSA"
          class="text-h9-regular lg:text-h7-medium mb-24 w-full text-center text-[#9fa1b6]"
        >
          Social Discovery Limited, 2850 W. Horizon Ridge Parkway, Suite 200, Henderson, Nevada
          89052
        </p>
        <p v-else class="text-h9-regular lg:text-h7-medium mb-24 w-full text-center text-[#9fa1b6]">
          WORIGIL LIMITED, Florinis 7, Greg Tower, 2nd floor, 1065, Nicosia, Cyprus
        </p>
      </div>
    </div>
    <div
      class="border-t-1 border-black-300 bg-blupur h-56 flex items-center justify-center border-solid py-12"
    >
      <div class="text-h9-regular text-white mr-12">We accept:</div>
      <div class="flex items-center gap-6">
        <IconVisa />
        <IconMastercard />
      </div>
    </div>
  </footer>
  <CookiesAccessModal />
  <CookiesAccessTopbar />
</template>
